@layer base {
	/* base styles */
	a {
		@apply underline;
	}
	h1 {
		@apply text-5xl font-bold text-black-primary md:text-7xl;
	}
	h2 {
		@apply text-3xl font-bold text-black-primary md:text-4xl;
	}
	h3 {
		@apply text-lg font-bold text-black-primary md:text-2xl;
	}
	h4 {
		@apply text-xs font-bold text-black-primary md:text-base;
	}

	/* Rich text */
	p {
		@apply text-black-primary;
	}
	.prose ul,
	.prose ol {
		@apply list-inside p-0;
	}
	.prose ul {
		@apply list-none;
	}
	.prose ul li,
	.prose ol li {
		@apply text-sm text-black-primary m-0 mb-2 pl-6 rtl:pl-0 rtl:pr-6 relative md:text-base;
	}
	.prose ol li {
		@apply pl-0 pr-0;
	}
	.prose ol li::before {
		content: "";
		width: 5px;
		display: inline-block;
	}
	.prose ul li:after {
		content: "";
		@apply absolute left-0 top-2 h-2 w-2 bg-red-primary rounded-full rtl:right-0;
	}
	.prose blockquote {
		background: url("data:image/svg+xml;charset=utf8,%3Csvg width='25' height='17' viewBox='0 0 25 17' xmlns='http://www.w3.org/2000/svg' focusable='false'%3E%3Ctitle%3EQuote marks%3C/title%3E%3Cpath d='M4.296 3.968c0 .81.097 1.6.28 2.367H10V17H0V8.76l.059-.07C.139 5.253 2.064 2.164 5.099 0a10.254 10.254 0 0 0-.803 3.968zm15.24 2.405H25V17H15V8.79h.001c.033-3.478 1.97-6.606 5.044-8.79a10.195 10.195 0 0 0-.805 3.956c0 .826.102 1.634.296 2.417z' fill='%23D0021B' fill-rule='evenodd'/%3E%3C/svg%3E")
			no-repeat 0 0;
		background-size: 25px 17px;
		@apply font-bold not-italic text-base leading-7 text-black-primary mb-10 pr-10 pl-10 border-0;
	}
	.prose blockquote::after {
		content: "";
		transform: scale(-1);
		width: 25px;
		height: 17px;
		background: url("data:image/svg+xml;charset=utf8,%3Csvg width='25' height='17' viewBox='0 0 25 17' xmlns='http://www.w3.org/2000/svg' focusable='false'%3E%3Ctitle%3EQuote marks%3C/title%3E%3Cpath d='M4.296 3.968c0 .81.097 1.6.28 2.367H10V17H0V8.76l.059-.07C.139 5.253 2.064 2.164 5.099 0a10.254 10.254 0 0 0-.803 3.968zm15.24 2.405H25V17H15V8.79h.001c.033-3.478 1.97-6.606 5.044-8.79a10.195 10.195 0 0 0-.805 3.956c0 .826.102 1.634.296 2.417z' fill='%23D0021B' fill-rule='evenodd'/%3E%3C/svg%3E")
			no-repeat 0 0;
		@apply absolute ml-3;
	}

	/* Column component */
	/* Link */
	.brc-column-layout .brc-link-container {
		@apply my-4;
	}
	/* Links on multicolumn */
	.brc-column-layout a {
		@apply my-4 block;
	}
	.brc-column-layout .brc-richtext a {
		@apply my-0 inline;
	}
	/* Rich text */
	.brc-column-layout .brc-richtext {
		@apply col-span-12 col-start-1 px-0 mb-4 lg:mb-4;
	}
	.brc-richtext ol,
	.brc-richtext ul {
		@apply mb-4 mt-0;
	}
	.brc-richtext ol li,
	.brc-richtext ul li {
		@apply mb-2;
	}
	.brc-richtext ol li::marker {
		@apply text-black-primary;
	}
	.brc-richtext ol li::before {
		width: 0px;
	}
	.brc-richtext ol ol {
		@apply list-[lower-alpha] list-inside pl-4 mb-0;
	}
	.brc-richtext ol ol ol {
		@apply list-disc list-inside pl-4 mb-0;
	}
	.brc-richtext ul ul ul,
	.brc-richtext ul ul {
		@apply pl-4 mb-0;
	}
	/* Image */
	.brc-column-layout img {
		@apply mb-4 aspect-video object-cover;
	}
	/* map-static image on multicolumn */
	.brc-column-layout .brc-map-static img {
		@apply mb-0;
	}
	/* Video, Quote */
	.brc-column-layout .brc-video,
	.brc-column-layout .brc-quote {
		@apply px-0 mb-8;
	}

	/* Remove quotation marks from beginning and end of QUOTE, added by tailwind prose */
	.brc-quote p:first-of-type::before,
	p:last-of-type::after {
		content: none;
	}

	/* Appropriate bottom margin before QUOTE component among rich text */
	.max-content:has(~ .brc-quote) .brc-richtext {
		margin-bottom: 24px;
	}

	@media (min-width: 768px) {
		.max-content:has(~ .brc-quote) .brc-richtext {
			margin-bottom: 16px;
		}
	}

	/* Shadow for inputs */
	.brc-shadow-fieldhover {
		box-shadow: 0 1px 0 rgb(38, 38, 38);
	}
	.brc-shadow-fieldfocus {
		box-shadow: 0 0 0 1px rgb(5, 133, 58);
	}

	/* Breadcrum home icon hover */
	.brc-breadcrumb-home a svg:hover {
		fill: #262626 !important;
	}

	/* Select dropdown style & icon */
	.brc-form-select {
		background-color: #ffffff;
		background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBMaWNlbnNlOiBQRC4gTWFkZSBieSBzdGVwaGVuaHV0Y2hpbmdzOiBodHRwczovL2dpdGh1Yi5jb20vc3RlcGhlbmh1dGNoaW5ncy9taWNyb25zIC0tPgo8c3ZnIGZpbGw9IiMwMDAwMDAiIHdpZHRoPSI4MDBweCIgaGVpZ2h0PSI4MDBweCIgdmlld0JveD0iMCAtNiA1MjQgNTI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciID48dGl0bGU+ZG93bjwvdGl0bGU+PHBhdGggZD0iTTY0IDE5MUw5OCAxNTcgMjYyIDMyMCA0MjYgMTU3IDQ2MCAxOTEgMjYyIDM4NyA2NCAxOTFaIiAvPjwvc3ZnPg==");
		background-size: 1.2rem;
		background-position: right 0.7rem center;
	}
	.brc-form-select:focus-visible {
		outline: 0;
		border-radius: 0;
		box-shadow: 0 0 0 1px rgb(5, 133, 58);
		border-width: 1px;
		--tw-border-opacity: 1;
		border-color: rgb(5 133 58 / var(--tw-border-opacity));
	}

	/* If Hero present with SearchForm on same page, remove Hero's margin bottom */
	.brc-hero-and-search {
		margin-bottom: 0 !important;
	}
	/* Page Hero with image */
	.brc-hero-with-image img {
		@apply object-cover aspect-auto h-full w-full;
	}

	/* Page Hero with video background, ofset to hide YouTube watermark */
	.brc-hero .brc-video-bg iframe {
		@apply w-[144%] h-[140%] absolute top-[-20%] left-[-22%];
	}
	.brc-hero .brc-hero-video-controls:hover svg {
		fill: #262626 !important;
	}
	.brc-video-bg div:first-child {
		@apply h-full w-full relative;
	}

	/* Width */
	.max-content {
		max-width: 1080px;
	}
	.max-content-hero {
		max-width: 1144px;
	}

	/* Remove default triangle on summary in Webkit browsers */
	.brc-no-summary-triangle::-webkit-details-marker {
		display: none;
	}

	/* Force the correct bottom margin to paragraphs in accordion rich text */
	.brc-accordion-section-text .brc-richtext {
		margin-bottom: 0 !important;
	}
}

.brc-no-rich-text-spacing {
	@apply -mx-4 -mb-6 md:-mb-12 lg:-mx-0 lg:-mb-12;
}

.brc-no-child-p-margins {
	& p {
		margin-bottom: 0 !important;
	}
}

.brc-rich-text-h2-margin {
	& h2:not(:first-of-type) {
		margin-top: 2.5rem;
	}
}
/* Default for Latin, Cyrillic, and Arabic scripts */
.font-sans {
	font-family: "Helvetica Neue", "helvetica", "arial", "sans-serif";
}
/* Arabic for: Arabic, Dari, Farsi, Kurdish Sorani, Pashto and Urdu */
.font-arabic {
	font-family: "Geeza Pro", "Segoe UI", "helvetica", "arial", "sans-serif";
}
/* Ethiopic for: Amharic and Tigrinya LTR, TODO possible extra to add "Noto Sans" */
.font-ethiopic {
	font-family: "Kefa", "Nyala", "arial", "sans-serif";
}
/* font-chinese: Cantonese, Mandarin, Simplified Chinese, Traditional Chinese TODO possible extra to add "Noto Sans TC" */
.font-chinese {
	font-family: "PingFang TC", "Microsoft JhengHei", "arial", "sans-serif";
}
/* Hindi - TODO posible to add "Noto Sans Devanagari" */
.font-devanagari {
	font-family:
		"Kohinoor Devanagari", "Devanagari MT", "Mangal", "Nirmala UI", "arial",
		"sans-serif";
}
